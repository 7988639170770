export enum UserPermissionList {
    ViewUsersList = 10,
    CreateUsers = 11,
    UpdateUsers = 12,
    DeleteUsers = 13,
    UpdateUsersPassword = 14,
    UpdateUsersPermissions = 15,
    ViewUsersPermissions = 16,
    ViewUsersAllowIp = 17,
    CreateUsersAllowIp = 18,
    DeleteUsersAllowIp = 19,
    RestoreUsers = 20,
    ViewUserPermissionList = 40,

    ViewQrBackendsList = 90,
    CreateQrBackend = 91,
    UpdateQrBackend = 92,
    DeleteQrBackend = 93,

    UpdateUserQrBackendPermissions = 100,
    ViewUserQrBackendPermissions = 101,
    ViewUserQrBackendPermissionList = 105,




    ViewQrCartList = 1001,
    ViewQrCartItem = 1002,
    ViewQrCartItemExtended = 1003,
    ViewQrCartItemCustomerDocuments = 1050,
    ViewQrCartItemArchiveDocuments = 1051,
    AllowQrCartItemSendEmailWithDocuments = 1060,
    AllowQrCartItemMakeRefund = 1100,
    AllowQrCartItemMakeRefundForce = 1101,

    ViewQrAcquirersList = 1500,

    ViewQrCartAcquirersRules = 1510,
    CreateQrCartAcquirersRules = 1511,
    DeleteQrCartAcquirersRules = 1512,

    ViewQrCartProcessingTest = 1601,
    MakeQrCartProcessingTest = 1602,
}
