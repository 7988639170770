import React, {useEffect, useState} from "react";
import AppMenu from "../AppBar/AppMenu";
import CssBaseline from "@mui/material/CssBaseline";
import {Alert, FormControl, Grid, InputLabel, MenuItem, Paper, Select} from "@mui/material";
import axios from "../axios";
import PasswordChangeFields from "../PasswordChange/PasswordChangeFields";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {LocalizationProvider, MobileDatePicker} from "@mui/x-date-pickers";
import TextField from "@mui/material/TextField";
import LoadingButton from '@mui/lab/LoadingButton';
import json2csv from "../json2csv";

export default function Overview() {
    const [isLoading, setLoading] = useState<boolean>(false);
    const [isLoaded, setLoaded] = useState<boolean>(false);

    const [createDateBegin, setCreateDateBegin] = useState<Date | undefined>(new Date())
    const [createDateEnd, setCreateDateEnd] = useState<Date | undefined>(new Date())

    const [terminalList, setTerminalList] = useState<{id: string, description: string}[]>([])
    const [terminalSelected, setTerminalSelected] = useState<string>('')

    const [providerList, setProviderList] = useState<{id: string, name: string}[]>([])
    const [providerSelected, setProviderSelected] = useState<string>('')

    const [reportData, setReportData] = useState<any[]>([])

    const loadReport = (dateBegin: string, dateEnd: string, provider: string, terminal: string) => {
        setLoaded(false)
        setLoading(true)
        axios.get(`/overview/${provider}/report?begin=${dateBegin}&end=${dateEnd}&terminal=${terminal}`, {withCredentials: true}).then(value => {
            const data = value.data.filter((v: any)=> parseInt(v.ret_amount) > 0 ).map((v: any)=> {
                return {
                    ...v,
                    ret_tim: v.ret_tim.substring(0,8),
                    ret_amount: parseFloat(v.ret_amount)
                }
            })
            setReportData(data)
            setLoaded(true)
            setLoading(false)
        }).catch(reason => {
            console.warn(reason)
            setLoading(false)
        })
    }

    const loadReportFile = (dateBegin: string, dateEnd: string, provider: string, terminal: string) => {
        setLoaded(false)
        setLoading(true)
        setReportData([])
        axios.get(`/overview/${provider}/report/file?begin=${dateBegin}&end=${dateEnd}&terminal=${terminal}`, {withCredentials: true, responseType: 'arraybuffer' }).then(value => {
            const contentDisposition = value.headers['content-disposition'];
            const filenameMatch = contentDisposition && contentDisposition.match(/filename=(.+?)(;|$)/);
            const filename = filenameMatch ? filenameMatch[1] : 'example.csv';

            const contentType = value.headers['content-type'];
            const charsetMatch = contentType && contentType.match(/charset=(.+?)(;|$)/);
            const charset = charsetMatch ? charsetMatch[1] : 'utf-8';

            // Create a Blob with the correct charset
            const blob = new Blob([value.data], { type: `text/csv; charset=${charset}` });
            // Create a link with the blob and trigger download
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = filename;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            setLoaded(true)
            setLoading(false)
        }).catch(reason => {
            setLoading(false)
        })
    }

    const loadTerminalList = (provider: string) => {
        setTerminalSelected('');
        axios.get(`/overview/${provider}/terminals`, {withCredentials: true}).then(value => {
            setTerminalList(value.data.map((v: any)=>{ return {id: v.terminal_id, description: v.adress} }))
            setTerminalSelected('all');
            // if (value.data.length < 5) setTerminalSelected('all');
        }).catch(reason => {

        })
    }

    const loadProviderList = () => {
        axios.get(`/overview/providers`, {withCredentials: true}).then(value => {
            setProviderList(value.data)
        }).catch(reason => {

        })
    }

    useEffect(()=>{
        if (providerSelected){
            loadTerminalList(providerSelected)
        }
    }, [providerSelected])

    useEffect(()=>{
        loadProviderList()
    }, [])

    return (
        <React.Fragment>
            <AppMenu header={'Reports'}/>
            <CssBaseline />
            <Paper sx={{m: 2}} elevation={0}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                            <FormControl fullWidth>
                                <InputLabel id="label">Provider</InputLabel>
                                <Select
                                    labelId="label"
                                    value={providerSelected || ''}
                                    label="Provider"
                                    onChange={(e)=>{setProviderSelected(e.target.value.toString())}}
                                >
                                    {
                                        providerList.map((value, index) => (
                                            <MenuItem key={`sv-${index}`} value={value.id}>{value.name}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                            <FormControl fullWidth>
                                <InputLabel id="label">Terminal</InputLabel>
                                <Select
                                    disabled={!providerSelected}
                                    labelId="label"
                                    value={terminalSelected || ''}
                                    label="Terminal"
                                    onChange={(e)=>{setTerminalSelected(e.target.value.toString())}}
                                >
                                    {/*{*/}
                                    {/*    terminalList.length < 5 &&*/}
                                        <MenuItem key={`sv-${0}`} value='all'>All</MenuItem>
                                    {/*}*/}
                                    {
                                        terminalList.map((value, index) => (
                                            <MenuItem key={`sv-${index}`} value={value.id}>{value.id}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                            <MobileDatePicker
                                disabled={!terminalSelected}
                                closeOnSelect
                                label="Report begin"
                                inputFormat="YYYY-DD-MM"
                                value={createDateBegin}
                                onChange={(e)=>{
                                    setCreateDateBegin(e ? new Date(e): undefined)
                                }}
                                renderInput={(params) => <TextField {...params} sx={{width: '100%'}}/>}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                            <MobileDatePicker
                                disabled={!terminalSelected}
                                closeOnSelect
                                label="Report end"
                                inputFormat="YYYY-DD-MM"
                                value={createDateEnd}
                                onChange={(e)=>{
                                    setCreateDateEnd(e ? new Date(e): undefined)
                                }}
                                renderInput={(params) => <TextField {...params} sx={{width: '100%'}}/>}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
                            <div style={{display: 'flex', alignItems: 'center', height: '100%'}}>
                                <LoadingButton
                                    size="large"
                                    onClick={()=>{
                                        const tzoffset = (new Date()).getTimezoneOffset() * 60000;
                                        if (new Date(createDateEnd!).getTime() - new Date(createDateBegin!).getTime() > 86400*1000) {
                                            loadReportFile((new Date(new Date(createDateBegin!).getTime() - tzoffset)).toISOString().substring(0,10), (new Date(new Date(createDateEnd!).getTime() - tzoffset)).toISOString().substring(0,10), providerSelected, terminalSelected )
                                        } else {
                                            loadReport((new Date(new Date(createDateBegin!).getTime() - tzoffset)).toISOString().substring(0,10), (new Date(new Date(createDateEnd!).getTime() - tzoffset)).toISOString().substring(0,10), providerSelected, terminalSelected )
                                        }
                                    }}
                                    loading={isLoading}
                                    loadingPosition="center"
                                    variant="outlined"
                                    color={"success"}
                                    fullWidth
                                    disabled={!terminalSelected}
                                >
                                   Load report
                                </LoadingButton>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
                            <div style={{display: 'flex', alignItems: 'center', height: '100%'}}>
                                <LoadingButton
                                    size="large"
                                    onClick={()=>{
                                        json2csv(reportData, `Report ${new Date().toLocaleString()}.csv`, {
                                            ret_terminalid: 'Terminal',
                                            ret_dat: 'Date',
                                            ret_tim: 'Time',
                                            ret_docid: 'Document number',
                                            ret_amount: 'Total',
                                            ret_comis: 'Fee',
                                            ret_debitname: 'Customer',
                                            ret_credit: 'Receiver account',
                                            ret_creditname: 'Receiver name',
                                            ret_purpose: 'Purpose',
                                        })
                                    }}
                                    loading={isLoading}
                                    disabled={!isLoaded || reportData.length == 0}
                                    loadingPosition="center"
                                    variant="outlined"
                                    color={"warning"}
                                    fullWidth
                                >
                                    Save to file
                                </LoadingButton>
                            </div>
                        </Grid>
                    </Grid>
                </LocalizationProvider>

                {
                    reportData.length == 0 &&
                    <Alert sx={{ mt: 2}} severity="warning">No data. Change the filter criteria and load report.</Alert>
                }
                {
                    reportData.length > 0 &&
                    <TableContainer component={Paper} sx={{mt: 2}}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Terminal</TableCell>
                                    <TableCell>Order</TableCell>
                                    <TableCell>Date</TableCell>
                                    <TableCell>Time</TableCell>
                                    <TableCell>Receipt number</TableCell>
                                    <TableCell>Total</TableCell>
                                    <TableCell>Fee</TableCell>
                                    <TableCell>Customer</TableCell>
                                    <TableCell>Receiver account</TableCell>
                                    <TableCell>Receiver name</TableCell>
                                    <TableCell>Purpose</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {reportData.map((row) => (
                                    <TableRow
                                        key={row.name}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell>{row.ret_terminalid}</TableCell>
                                        <TableCell>{row.ret_transname}</TableCell>
                                        <TableCell>{row.ret_dat}</TableCell>
                                        <TableCell>{row.ret_tim}</TableCell>
                                        <TableCell>{row.ret_docid}</TableCell>
                                        <TableCell>{row.ret_amount}</TableCell>
                                        <TableCell>{row.ret_comis}</TableCell>
                                        <TableCell>{row.ret_debitname}</TableCell>
                                        <TableCell>{row.ret_credit}</TableCell>
                                        <TableCell>{row.ret_creditname}</TableCell>
                                        <TableCell>{row.ret_purpose}</TableCell>

                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                }
            </Paper>
        </React.Fragment>
    )
}
