import axios from "axios";
import React, {useEffect, useState} from "react";
import setCredential from "./Auth/setCredential";
import {Dialog} from "@mui/material";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import PasswordChangeFields from "./PasswordChange/PasswordChangeFields";

axios.defaults.baseURL = 'https://rpt.airas.com.ua/api';
// axios.defaults.baseURL = 'http://localhost:3000';
axios.defaults.withCredentials = true;

// axios instance
const instance = axios.create();

let isRefreshing = false;
let refreshSubscribers: any[] = [];

function subscribeTokenRefresh(cb: any) {
    refreshSubscribers.push(cb);
}

function onRrefreshed() {
    refreshSubscribers.map(cb => cb());
}

async function refresh(token: string): Promise<{ access: string; refresh: string }> {
    const res = await axios.create().post('/user/refresh', {refreshToken: token})
    if (res.data) return res.data;
    throw new Error('fetch key error')
}

export function AxiosInterceptor(props: {children: JSX.Element}){
    const [isPasswordChaneRequired, setPasswordChaneRequired] = useState(false);

    useEffect(() => {
        const resInterceptor = (response: any) => {
            return response;
        };

        const errInterceptor = async (error: any) => {
            // anti loop
            if (error.config.headers['retry']) return Promise.reject(error);
            error.config.headers['retry'] = true;
            if (window.location.pathname === '/signin') return Promise.reject(error);

            switch (error.response.status) {
                case 401 :
                    if (!isRefreshing) {
                        isRefreshing = true;

                        const refreshTokenLocalstorageName = 'refreshToken';
                        const refreshTokenLocalstorageValue = localStorage.getItem(refreshTokenLocalstorageName) || undefined;
                        if (refreshTokenLocalstorageValue) {
                            const r = await refresh(refreshTokenLocalstorageValue).catch(reason => {
                                window.location.href = '/signin'
                            })
                            if (r) {
                                setCredential(r.access, r.refresh)
                                isRefreshing = false;
                                onRrefreshed()
                                // return axios.request(error.config);
                            }
                        } else {
                            window.location.href = '/signin'
                        }
                        // refreshAccessToken()
                        //     .then(newToken => {
                        //         isRefreshing = false;
                        //         onRrefreshed(newToken);
                        //     });
                    }

                    return axios.request(error.config);

                    break
                case 412:
                    setPasswordChaneRequired(true)
                    break;
                default :
                    break
            }
            return Promise.reject(error);
        };

        const interceptor = instance.interceptors.response.use(
            resInterceptor,
            errInterceptor
        );

        return () => instance.interceptors.response.eject(interceptor);
    }, []);

    if (isPasswordChaneRequired){
        return (
            <Dialog
                fullScreen
                open={true}
            >
                <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <Box
                        sx={{
                            marginTop: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Typography component="h1" variant="h5">
                            Password change require
                        </Typography>

                        <Typography component="p" variant="caption" align={"center"}>
                            For security reason you required to change password every 30 days and on first sign in
                        </Typography>

                        <PasswordChangeFields/>
                    </Box>
                </Container>
            </Dialog>

        )
    }

    return props.children;
}

export default instance;
