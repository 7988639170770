import React, {useEffect, useState} from "react";
import {UserPermissionItem} from "../interfaces/UserPermissionItem";
import {Checkbox, FormControlLabel, FormGroup} from "@mui/material";
import Button from "@mui/material/Button";
import instance from "../axios";

export interface Props {
    user: number
    backend: number
}

export default function UserEditQRBackendPermissions(props: Props) {
    const [selectedUserId, setSelectedUserId] = useState<number>(0);

    const [permissionList, setPermissionList] = useState<UserPermissionItem[]>([])
    const [userPermissionList, setUserPermissionList] = useState<UserPermissionItem[]>([])
    const [userPermissionListChanged, setUserPermissionListChanged] = useState<UserPermissionItem[]>([])
    const [isUserPermissionChanged, setUserPermissionChanged] = useState<boolean>(false)

    useEffect(()=>{
        loadPermissionList();
        if (props.user > 0){
            setSelectedUserId(props.user)
            loadUserPermissions(props.user, props.backend)
        }
    }, [props.user]);

    const loadUserPermissions = (userId: number, backendId: number) => {
        instance.get(`/user/oq/${backendId}/permission/user/${userId}`, {withCredentials: true}).then(value => {
            setUserPermissionList(value.data.map((v:any)=>v.permission))
            setUserPermissionListChanged(value.data.map((v:any)=>v.permission))
        }).catch(reason => {

        })
    }

    const loadPermissionList = () => {
        instance.get(`/user/oq/permission/list`, {withCredentials: true}).then(value => {
            setPermissionList(value.data)
        }).catch(reason => {

        })
    }

    const saveUserPermissions = (userId: number, backendId: number, list: UserPermissionItem[]) => {
        instance.post(`/user/oq/${backendId}/permission/user/${userId}`, [...list.map(value => value.id)], {withCredentials: true}).then(value => {

        }).catch(reason => {

        })
    }

    useEffect(()=>{
        const sortedA = userPermissionList.sort((a, b) => a.id - b.id);
        const sortedB = userPermissionListChanged.sort((a, b) => a.id - b.id);
        setUserPermissionChanged(JSON.stringify(sortedA) === JSON.stringify(sortedB))
    }, [userPermissionList, userPermissionListChanged])

    return (
        <React.Fragment>
            <FormGroup>
                {
                    permissionList.map((value, index) => (
                        <FormControlLabel control={
                            <Checkbox
                                checked={!!userPermissionListChanged.find((v) => v.id === value.id)}
                                onChange={(e)=> {
                                    if (e.target.checked){
                                        setUserPermissionListChanged([...userPermissionListChanged, value])
                                    } else {
                                        setUserPermissionListChanged([...userPermissionListChanged.filter((v)=>v.id !== value.id)])
                                    }
                                }}
                            />
                        } label={value.name} />
                    ))
                }
            </FormGroup>
            <div style={{display: "flex"}}>
                <Button sx={{mx: 0, mt: 1}} variant="outlined" fullWidth disabled={isUserPermissionChanged} onClick={saveUserPermissions.bind(null, selectedUserId, props.backend, userPermissionListChanged)}>Save</Button>
            </div>
        </React.Fragment>
    )
}
