import {
    Avatar,
    IconButton,
    List,
    ListItem,
    ListItemAvatar, ListItemSecondaryAction,
    ListItemText,
    Paper
} from "@mui/material";
import {useEffect, useState} from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import React from "react";
import AppMenu from "../AppBar/AppMenu";
import CssBaseline from "@mui/material/CssBaseline";
import Button from "@mui/material/Button";
import UserEditDialog from "./UserEditDialog";
import UserCreateDialog from "./UserCreateDialog";
import PersonIcon from '@mui/icons-material/Person';
import PopupDialog from "../Components/Dialog/PopupDialog";
import BlockIcon from '@mui/icons-material/Block';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import instance from "../axios";
import UserRestoreDialog from "./UserRestoreDialog";

export interface UserListItem {
    id: number;
    login: string;
    isActive: boolean;
}

export default function UserList() {
    const [userList, setUserList] = useState<UserListItem[]>([])
    const [selectedUserId, setSelectedUserId] = useState<number>(0);
    const [deletedUserId, setDeletedUserId] = useState<number>(0);
    const [isNeedCreateUser, setNeedCreateUser] = useState<boolean>(false);
    const [restoringUserId, setRestoringUserId] = useState<number>(0);
    const [isNeedRestoreUser, setNeedRestoreUser] = useState<boolean>(false);

    const loadUserList = () => {
        instance.get('/user/list').then(value => {
            setUserList(value.data)
        }).catch(reason => {

        })
    }

    const deleteUser = (userId: number) => {
        instance.delete(`/user/list/${userId}`).then(value => {
            setDeletedUserId(0)
            loadUserList()
        }).catch(reason => {

        })
    }

    useEffect(()=>{
        loadUserList()
    }, [])

    return (
        <React.Fragment>
            <AppMenu header={'System user'}/>
            <CssBaseline />
            <div style={{display: "flex"}}>
                <Button sx={{mx: 2, mt: 2}} variant="outlined" fullWidth onClick={()=>{setNeedCreateUser(true)}}>Create new user</Button>
            </div>
            <Paper sx={{m: 2}}>
                <List>
                    {
                        userList.map((value, index) => (
                            <ListItem
                                disabled={!value.isActive}
                            >
                                <ListItemAvatar aria-disabled={!value.isActive}>
                                    <Avatar>
                                        {value.isActive ? <PersonIcon /> : <BlockIcon />}
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText color="red"
                                    primary={value.login}
                                />
                                <ListItemSecondaryAction>
                                    <React.Fragment>
                                        <IconButton disabled={!value.isActive} aria-label="edit" onClick={()=>{setSelectedUserId(value.id)}}>
                                            <EditIcon />
                                        </IconButton>
                                        {
                                            value.isActive ?
                                                <IconButton edge="end" aria-label="delete" color={"error"} onClick={()=>{setDeletedUserId(value.id)}}>
                                                    <DeleteIcon />
                                                </IconButton>
                                                :
                                                <IconButton edge="end" aria-label="restore" color="success" onClick={()=>{setRestoringUserId(value.id); setNeedRestoreUser(true);}}>
                                                    <AutorenewIcon />
                                                </IconButton>
                                        }

                                    </React.Fragment>
                                </ListItemSecondaryAction>
                            </ListItem>
                        ))
                    }
                </List>
            </Paper>
            {
                selectedUserId > 0 &&
                <UserEditDialog userId={selectedUserId} onClose={()=>{setSelectedUserId(0)}}/>
            }
            {
                isNeedCreateUser &&
                <UserCreateDialog reloadParent={()=>{loadUserList()}} onClose={()=>{setNeedCreateUser(false)}}/>
            }
            {
                isNeedRestoreUser &&
                <UserRestoreDialog userId={restoringUserId} reloadParent={()=>{loadUserList()}} onClose={()=>{setRestoringUserId(0); setNeedRestoreUser(false);}}/>
            }
            {
                deletedUserId > 0 &&
                <PopupDialog maxWidth={'sm'} title={'Confirm delete user'} onClose={()=>{setDeletedUserId(0)}}>
                    <React.Fragment>
                        <Button sx={{mt: 2}} variant="outlined" color={"error"} fullWidth onClick={deleteUser.bind(null, deletedUserId)}>Delete</Button>
                    </React.Fragment>
                </PopupDialog>
            }
        </React.Fragment>

    )
}
