import React, {useEffect, useState} from "react";
import PopupDialog from "../../Components/Dialog/PopupDialog";
import instance from "../../axios";
import {Avatar, Button} from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import NextPlanIcon from "@mui/icons-material/NextPlan";
import CircleIcon from "@mui/icons-material/Circle";
import PaymentReverseDialog from "./PaymentReverseDialog";

const getStatusIcon = (status: number) => {
    switch(status) {
        case 0: return <CheckCircleIcon color={"success"}/>
        case 100: return <NextPlanIcon color={"warning"}/>
        default: return <CircleIcon color={"disabled"}/>
    }
}

interface Props {
    uuid: string
    backend: number
    provider: number
    onClose: () => void
}

export default function PaymentAcquiring(props: Props) {
    const [statuses, setAttempt] = useState<any[]>([]);
    const [selectedReverseId, setSelectedReverseId] = useState<number>(0);

    const loadAttemptsHistory = (uuid: string, backend: number, paymentProviderId: number) => {
        instance.post(`/oq/s2s/${backend}/qr/cart/attempts/extended`, {uuid, paymentProviderId}).then(value => {
            setAttempt(value.data.payment.statuses)
        }).catch(reason => {

        })
    }

    useEffect(()=>{
        if (props.uuid && props.backend){
            loadAttemptsHistory(props.uuid, props.backend, props.provider)
        }
    }, [props.uuid, props.backend])

    return (
        <React.Fragment>
            <PopupDialog title={props.uuid} maxWidth={'lg'} onClose={()=>{props.onClose()}}>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Date</TableCell>
                                <TableCell>Order</TableCell>
                                <TableCell>RRN</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Amount</TableCell>
                                <TableCell align="center">#</TableCell>
                                <TableCell align="center">#</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {statuses.map((value, index) => (
                                <TableRow
                                    key={value.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {new Date(value.lastServerDatetime).toLocaleString()}
                                    </TableCell>
                                    <TableCell align="right">{value.paymentOrderId}</TableCell>
                                    <TableCell align="right">{value.paymentBackrefRRN}</TableCell>
                                    <TableCell align="right">{value.payerEmail}</TableCell>
                                    <TableCell align="right">{value.lastServerStatus == 0 ? value.paymentServerAmount : ""}</TableCell>
                                    <TableCell align="center">{getStatusIcon(value.lastServerStatus)}</TableCell>
                                    <TableCell align="center">
                                        <Button variant="outlined" size={"small"} onClick={()=>{setSelectedReverseId(value.id)}} disabled={false} fullWidth color={'error'}>reverse transaction</Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </PopupDialog>
            {
                selectedReverseId > 0 &&
                <PaymentReverseDialog payment={selectedReverseId} provider={props.provider} backend={props.backend} uuid={props.uuid} onClose={()=>{setSelectedReverseId(0)}}/>
            }
        </React.Fragment>

    )
}
