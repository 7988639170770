declare global {
    interface Navigator {
        msSaveBlob?: (blob: any, defaultName?: string) => boolean
    }
}

export default function json2csv(data: any[], fileName: string, header: any){
    const filename = fileName || `export.csv`
    const keyHeader = Object.keys(header)
    let csv = [];
    csv.push(`"${keyHeader.map((v)=> header[v]).join('";"')}"`);
    for (const dataRow of data) {
        csv.push(`"${keyHeader.map((v)=> dataRow[v]).join('";"')}"`);
    }

    //const blob = new Blob([csv.join('\r\n')], {
    //    type: 'application/csv;charset=windows-1251',
    //})

    //if (navigator.msSaveBlob) {
    //    navigator.msSaveBlob(blob, filename)
    //    return
    //}
    const link = document.createElement('a')

    link.href = 'data:text/csv; charset=windows-1251,' + encodeURIComponent("\uFEFF"+csv.join('\r\n'))
    link.download = filename
    link.style.display = 'none'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
}
