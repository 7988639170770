import {
    AppBar, Collapse,
    Drawer,
    IconButton, List,
    ListItemButton,
    ListItemIcon, ListItemText,
    Toolbar,
    Typography,
    useTheme
} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';

import Brightness7Icon from '@mui/icons-material/Brightness7';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import {ColorModeContext, UIPermissionContext} from "../App";
import React, {useState} from "react";
import GroupIcon from '@mui/icons-material/Group';
import HomeIcon from '@mui/icons-material/Home';
import {UserPermissionList} from "../enums/UserPermissionList";
import {Link} from "react-router-dom";

export interface Props {
    header?: string
}

export default function AppMenu(props: Props) {
    const theme = useTheme();
    const colorMode = React.useContext(ColorModeContext);
    const uiPermission = React.useContext(UIPermissionContext);

    const [isMenuOpen, setMenuOpen] = useState<boolean>(false);

    const [isOnlineQROpen, setOnlineQROpen] = useState<boolean>(false);

    return (
        <AppBar position="static">
            <Toolbar>
                <IconButton
                    onClick={colorMode.toggleColorMode}
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ mr: 2 }}
                >
                    {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
                </IconButton>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    {props.header}
                </Typography>
                <IconButton
                    size="large"
                    edge="end"
                    color="inherit"
                    aria-label="menu"
                    onClick={()=>{setMenuOpen(!isMenuOpen)}}
                    sx={{ ml: 2 }}
                >
                    <MenuIcon />
                </IconButton>
            </Toolbar>
            <Drawer
                anchor={"right"}
                open={isMenuOpen}
                onClose={()=>{setMenuOpen(!isMenuOpen)}}
            >
                <React.Fragment>
                    <List>
                        <ListItemButton component={Link} to="/">
                            <ListItemIcon>
                                <HomeIcon />
                            </ListItemIcon>
                            <ListItemText primary="Overview" />
                        </ListItemButton>
                        {
                            uiPermission.check([UserPermissionList.ViewUsersList]) &&
                            <ListItemButton component={Link} to="/user">
                                <ListItemIcon>
                                    <GroupIcon />
                                </ListItemIcon>
                                <ListItemText primary="User settings" />
                            </ListItemButton>
                        }

                    </List>

                </React.Fragment>
            </Drawer>
        </AppBar>
        )

}
