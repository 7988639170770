import React, {useEffect, useState} from 'react';
import instance from "../../axios";
import {
    Alert,
    CircularProgress,
    Dialog,
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup
} from "@mui/material";
import PopupDialog from "../../Components/Dialog/PopupDialog";
import Button from "@mui/material/Button";

interface Props {
    uuid: string
    backend: number
    provider: number
    payment: number
    onClose: () => void
}

export default function PaymentReverseDialog(props: Props) {
    const [ isReverseAvailable, setReverseAvailable ] = useState<boolean>(false);
    const [ isReverseAvailablePending, setReverseAvailablePending ] = useState<boolean>(false);

    const [ isMakeReversePending, setMakeReversePending ] = useState<boolean>(false);

    const checkReverseAvailability = (uuid: string, backend: number, paymentProviderId: number, attemptId: number) => {
        setReverseAvailablePending(true)
        instance.post(`/oq/s2s/${backend}/qr/cart/reverse-check`, {uuid, paymentProviderId, attemptId}).then(value => {
            setReverseAvailable(value.data)
            setReverseAvailablePending(false)
        }).catch(reason => {

        })
    }

    const makeReverseImmediately = (uuid: string, backend: number, paymentProviderId: number, attemptId: number) => {
        setMakeReversePending(true);

        instance.post(`/oq/s2s/${backend}/qr/cart/reverse-immediately`, {uuid, paymentProviderId, attemptId, force: false}).then((r: any) => {
            console.log(r.data)
            if (r.data.acquire && r.data.acquire.payment && r.data.acquire.url) {
                const form = document.createElement('form');
                form.setAttribute('action', r.data.acquire.url);
                form.setAttribute('method', 'post');
                form.setAttribute('target', '_blank');

                for (const [key, value] of Object.entries(r.data.acquire.payment)) {
                    const input = document.createElement('input');
                    input.setAttribute('hidden', 'hidden');
                    input.setAttribute('name', key);
                    input.setAttribute('value', value as string);
                    form.appendChild(input);
                }
                document.documentElement.appendChild(form).submit();
            }

            setMakeReversePending(false);
            props.onClose()
        }).catch(reason => {
            setMakeReversePending(false);
        })
    }

    useEffect(()=>{
        if (props.uuid && props.backend && props.provider && props.payment){
            checkReverseAvailability(props.uuid, props.backend, props.provider, props.payment)
        }
    }, [props.uuid, props.backend, props.provider, props.payment])

    return (
        <React.Fragment>
            {
                props.uuid && props.backend && props.provider && props.payment &&
                <PopupDialog title={props.uuid} onClose={()=>{props.onClose()}} maxWidth={'sm'}>
                    {
                        isReverseAvailablePending ?
                            <CircularProgress />
                            :
                            isReverseAvailable ?
                                <>
                                    <Alert severity="success" sx={{ marginBottom: 2 }}>Refund is available</Alert>
                                    <Alert severity="error"><b>cancellation will be made immediately</b></Alert>
                                    <Button sx={{ mt: 2}} variant="outlined" fullWidth onClick={makeReverseImmediately.bind(null, props.uuid, props.backend, props.provider, props.payment)} disabled={isMakeReversePending}>Refund</Button>
                                </>
                                :
                                <Alert severity="warning">Refund locked</Alert>
                    }
                </PopupDialog>
            }
        </React.Fragment>
    )
}
