import {useEffect, useState} from "react";
import instance from "../axios";
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";

interface Props {
    setBackendId(id: number): void
}

export interface OnlineQrBackendItem {
    id: number;
    name: string;
    isHealth: boolean
}

export default function OQBackendSelect(props: Props) {
    const [backendList, setBackendList] = useState<OnlineQrBackendItem[]>([])
    const [backendSelected, setBackendSelected] = useState<number>(0)

    const loadBackendList = () => {
        instance.get('/oq/backend/list').then(value => {
            setBackendList(value.data)
        }).catch(reason => {

        })
    }

    useEffect(()=>{
        loadBackendList();
    }, [props])

    useEffect(()=>{
        if (backendList.length === 1){
            setBackendSelected(backendList[0].id)
        }
    }, [backendList])

    useEffect(()=>{
        props.setBackendId(backendSelected)
    }, [backendSelected])

    return (
        <FormControl fullWidth>
            <InputLabel id="label">Backend</InputLabel>
            <Select
                labelId="label"
                value={backendSelected.toString() || ''}
                label="Backend"
                onChange={(e)=>{setBackendSelected(parseInt(e.target.value.toString()))}}
            >
                {
                    backendList.map((value, index) => (
                        <MenuItem key={`sv-${index}`} value={value.id}>{value.name}</MenuItem>
                    ))
                }
            </Select>
        </FormControl>

    )
}
