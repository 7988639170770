import React, {useEffect, useState} from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import {
    IconButton,
    List, ListItem,
    Paper
} from "@mui/material";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import instance from "../axios";

export interface Props {
    userId: number
}

export default function UserEditIPs(props: Props) {
    const [userAllowIp, setUserAllowIp] = useState<{id: number, ip: string, netmask: string}[]>([])

    const [userAllowIpAddressInput, setUserAllowIpAddressInput] = useState<string>('');
    const [userAllowIpAddress, setUserAllowIpAddress] = useState<{ip: string; netmask: string}>();
    const [userAllowIpAddressValid, setUserAllowIpAddressValid] = useState<boolean>(false);

    useEffect(()=>{
        if (props.userId > 0){
            loadUserAllowIps(props.userId)
        }
    }, [props.userId]);

    useEffect(()=>{
        setUserAllowIpAddressValid(false);
        const host = userAllowIpAddressInput.match(/^((([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))|((?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)))/)
        const network = userAllowIpAddressInput.match(/^((([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))|((?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)))\/([0-9]|1[0-9]|2[0-9]|3[0-2])?$/)

        if (host){
            console.log(host[0])
            setUserAllowIpAddressValid(true);
            setUserAllowIpAddress({
                ip: host[0],
                netmask: '32'
            })
        }
        if (network){
            console.log(network[33], network[1])
            if (network[1] && network[33]){
                setUserAllowIpAddressValid(true);
                setUserAllowIpAddress({
                    ip: network[1],
                    netmask: network[33]
                })
            }
        }
    }, [userAllowIpAddressInput])

    const addUserAllowIp = (userId: number, allow?: {ip: string; netmask: string} ) => {
        if (allow === undefined || allow.ip === undefined || allow.netmask == undefined) return undefined;
        instance.post(`/user/ip/user/${userId}`, {ip: allow.ip, netmask: allow.netmask}, {withCredentials: true}).then(value => {
            setUserAllowIpAddressInput('');
            loadUserAllowIps(props.userId)
        }).catch(reason => {

        })
    }

    const loadUserAllowIps = (userId: number) => {
        instance.get(`/user/ip/user/${userId}`, {withCredentials: true}).then(value => {
            setUserAllowIp(value.data.ips)
        }).catch(reason => {

        })
    }

    const deleteAllowIps = (id: number) => {
        instance.delete(`/user/ip/${id}`, {withCredentials: true}).then(value => {
            loadUserAllowIps(props.userId)
        }).catch(reason => {

        })
    }

    return (
        <React.Fragment>
            <TextField
                required
                fullWidth
                label="IPv4 and IPv6 (netmask supported)"
                value={userAllowIpAddressInput}
                onChange={(e)=>{setUserAllowIpAddressInput(e.target.value)}}
                error={userAllowIpAddressInput.length > 0 && !userAllowIpAddressValid}
            />
            <Button sx={{mt: 1}} variant="outlined" fullWidth onClick={addUserAllowIp.bind(null, props.userId, userAllowIpAddress)} disabled={!userAllowIpAddressValid}>Add to whitelist</Button>

            <Paper sx={{mt: 1}}>
                <List>
                    {
                        userAllowIp.map((value, index) => (
                            <ListItem
                                key={`li-${index}`}
                                secondaryAction={
                                    <React.Fragment>
                                        <IconButton edge="end" aria-label="delete" color={"error"} onClick={deleteAllowIps.bind(null, value.id)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </React.Fragment>
                                }
                            >{`${value.ip}/${value.netmask}`}</ListItem>
                        ))
                    }
                </List>
            </Paper>


        </React.Fragment>
    )
}
