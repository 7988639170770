import Typography from "@mui/material/Typography";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
} from "@mui/material";
import React, {useEffect, useState} from "react";
import PopupDialog from "../Components/Dialog/PopupDialog";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import UserEditPermissions from "./UserEditPermissions";
import UserEditIPs from "./UserEditIPs";
import instance from "../axios";
import UserQRBackendPersonalDataLevel from "./UserQRBackendPersonalDataLevel";
import UserEditQRBackendPermissions from "./UserEditQRBackendPermissions";
import Button from "@mui/material/Button";
import UserRestoreDialog from "./UserRestoreDialog";
import UserResetPasswordDialog from "./UserResetPasswordDialog";

export interface OnlineQrBackendItem {
    id: number;
    name: string;
    isHealth: boolean
}

export interface Props {
    userId: number
    onClose: () => void
}

export default function UserEditDialog(props: Props) {
    const [isNeedResetPassword, setNeedResetPassword] = useState<boolean>(false);

    const [selectedUserId, setSelectedUserId] = useState<number>(0);

    const [qrBackendList, setQrBackendList] = useState<OnlineQrBackendItem[]>([])

    const loadBackendList = () => {
        instance.get('/oq/backend/list').then(value => {
            setQrBackendList(value.data)
        }).catch(reason => {

        })
    }

    useEffect(()=>{
        loadBackendList()
    }, [])

    useEffect(()=>{
        if (props.userId > 0){
            setSelectedUserId(props.userId)
        }
    }, [props.userId]);

    return (
        <PopupDialog title={'User info'} maxWidth={'sm'} onClose={()=>{props.onClose()}}>
            <React.Fragment>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                    >
                        <Typography>Permissions</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                       <UserEditPermissions userId={selectedUserId}/>
                    </AccordionDetails>
                </Accordion>


                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                    >
                        <Typography>IP Access</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <UserEditIPs userId={selectedUserId}/>
                    </AccordionDetails>
                </Accordion>

                {
                    qrBackendList.map((v, i)=>(
                        <Accordion key={`qrbl-${i}`}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                            >
                                <Typography>{v.name}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <UserQRBackendPersonalDataLevel user={selectedUserId} backend={v.id}/>
                                <UserEditQRBackendPermissions user={selectedUserId} backend={v.id}/>
                            </AccordionDetails>
                        </Accordion>
                    ))
                }
                <div style={{display: "flex"}}>
                    <Button sx={{mt: 2}} variant="outlined" fullWidth onClick={()=>{setNeedResetPassword(true)}}>Reset password</Button>
                </div>
                {
                    isNeedResetPassword &&
                    <UserResetPasswordDialog userId={props.userId} reloadParent={()=>{}} onClose={()=>{setNeedResetPassword(false)}}/>
                }
            </React.Fragment>
        </PopupDialog>

    )
}
