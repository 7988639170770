export default function setCredential(accessToken: string, refreshToken: string) {
    const accessTokenCookieName = 'accessToken';
    const accessTokenCookieValue = accessToken;
    const accessTokenCookieExpire = new Date(new Date().getTime() + (600 * 1000))

    const domain = window.location.hostname;

    //const cookie = `${accessTokenCookieName}=${accessTokenCookieValue};expires=${accessTokenCookieExpire.toUTCString()};domain=.${domain};path=/`;

    //document.cookie = cookie;

    const cookieLocal = `${accessTokenCookieName}=${accessTokenCookieValue};expires=${accessTokenCookieExpire.toUTCString()};path=/`;

    document.cookie = cookieLocal;

    const refreshTokenLocalstorageName = 'refreshToken';
    const refreshTokenLocalstorageValue = refreshToken;

    localStorage.setItem(refreshTokenLocalstorageName, refreshTokenLocalstorageValue);
}
