import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import {Alert, LinearProgress} from "@mui/material";
import Button from "@mui/material/Button";
import encryptWithPublicKey from "../crypto";
import axios from "axios";

function getPasswordScore(password: string): number{
    let score = 0;
    //If password contains both lower and uppercase characters
    if (password.match(/([a-z].*[A-Z])|([A-Z].*[a-z])/)) score += 1
    //If it has numbers and characters
    if (password.match(/([0-9])/)) score += 1
    //If it has one special character
    if (password.match(/([!,%,&,@,#,$,^,*,?,_,~])/)) score += 1
    //If password is greater than 9
    if (password.length > 9) score += 1

    return score;
}

export default function PasswordChangeFields() {
    const [oldPassword, setOldPassword] = useState<string>('');
    const [newPassword, setNewPassword] = useState<string>('');
    const [newPasswordConfirmation, setNewPasswordConfirmation] = useState<string>('');

    const [passwordScorePercentValue, setPasswordScorePercentValue] = useState<number>(0)
    const [passwordScorePercentColor, setPasswordScorePercentColor] = useState<'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | 'inherit'>('error')
    const [isNewPasswordGreat, setNewPasswordGreat] = useState<boolean>(false);
    const [isNewPasswordConfirmed, setNewPasswordConfirmed] = useState<boolean>(false);

    const [isPasswordChanging, setPasswordChanging] = useState<boolean>(false);
    const [passwordChangingError, setPasswordChangingError] = useState<string>('');

    useEffect(()=>{
        setNewPasswordGreat(false);
        const score = getPasswordScore(newPassword);
        switch (score) {
            case 1:
                setPasswordScorePercentColor('error')
                setPasswordScorePercentValue(25)
                break;
            case 2:
                setPasswordScorePercentColor('error')
                setPasswordScorePercentValue(50)
                break;
            case 3:
                setPasswordScorePercentColor('warning')
                setPasswordScorePercentValue(75)
                break;
            case 4:
                setPasswordScorePercentColor('success')
                setPasswordScorePercentValue(100)
                setNewPasswordGreat(true);
                break;
            default:
                setPasswordScorePercentColor('inherit')
                setPasswordScorePercentValue(0)
                break;
        }
    }, [newPassword])

    useEffect(()=>{
        setNewPasswordConfirmed(false);
        if (newPassword === newPasswordConfirmation && isNewPasswordGreat){
            setNewPasswordConfirmed(true);
        }
    }, [newPassword, newPasswordConfirmation, isNewPasswordGreat])

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const encryptedData = await encryptWithPublicKey({
            passwordOld: data.get('old-password'),
            passwordNew: data.get('new-password'),
        })

        setPasswordChanging(true);
        setPasswordChangingError('');
        //axios.post<{ access: string; refresh: string }>('/user/password-change/encrypted', {payload: encryptedData}, {withCredentials: true})
        axios.post<{ access: string; refresh: string }>('/user/password-change', {passwordOld: data.get('old-password'), passwordNew: data.get('new-password')}, {withCredentials: true})
            .then((value)=>{
                setPasswordChanging(false);
                window.location.reload()
            })
            .catch((error) => {
                setPasswordChangingError(error.message);
                setPasswordChanging(false);
            })

    };

    return (
        <React.Fragment>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="old-password"
                    label="Old password"
                    type="password"
                    autoComplete="current-password"
                    value={oldPassword}
                    onChange={(e)=>{setOldPassword(e.target.value)}}
                />

                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="new-password"
                    label="New password"
                    type="password"
                    id="new-password"
                    autoComplete="new-password"
                    sx={{ mt: 3, mb: 1 }}
                    value={newPassword}
                    onChange={(e)=>{setNewPassword(e.target.value)}}
                    error={!isNewPasswordGreat && newPassword.length > 0}
                />
                {
                    newPassword.length > 0 &&
                    <LinearProgress variant="determinate" value={passwordScorePercentValue} color={passwordScorePercentColor}/>
                }
                {
                    isNewPasswordGreat &&
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="new-password-confirmation"
                        label="New password confirmation"
                        type="password"
                        id="new-password-confirmation"
                        autoComplete="new-password-confirmation"
                        sx={{ mt: 1, mb: 0 }}
                        value={newPasswordConfirmation}
                        onChange={(e)=>{setNewPasswordConfirmation(e.target.value)}}
                        error={!isNewPasswordConfirmed}
                    />
                }
                <Button
                    type="submit"
                    fullWidth
                    variant="outlined"
                    sx={{ mt: 3, mb: 2 }}
                    disabled={!isNewPasswordConfirmed || !(oldPassword.length > 0 || isPasswordChanging)}
                >
                    Change password
                </Button>
                {
                    passwordChangingError &&
                    <Alert severity="error">{passwordChangingError}</Alert>
                }
            </Box>
        </React.Fragment>
    )
}
