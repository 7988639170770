import axios from "axios";
import { JSEncrypt } from "jsencrypt";

export default async function encryptWithPublicKey(payload: any): Promise<string> {
    const encrypt = new JSEncrypt();
    encrypt.setPublicKey(await getPublicKey());

    return encrypt.encrypt(JSON.stringify(payload)).toString()
}

async function getPublicKey(): Promise<string> {
    const res = await axios.get('/crypto/public')
    if (res.data.key) return res.data.key;
    throw new Error('fetch key error')
}
