import {Alert, Checkbox, Dialog, DialogContent, DialogTitle, IconButton} from "@mui/material";
import React, {useEffect, useState} from "react";
import encryptWithPublicKey from "../crypto";
import PopupDialog from "../Components/Dialog/PopupDialog";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import instance from "../axios";

export interface Props {
    reloadParent: () => void
    onClose: () => void
}

export default function OQBackendCreateDialog(props: Props) {
    const [backendName, setBackendName] = useState<string>('');
    const [backendUrl, setBackendUrl] = useState<string>('');
    const [backendToken, setBackendToken] = useState<string>('');


    const [isUserCreated, setUserCreated] = useState<boolean>(false);
    const [userCreateError, setUserCreateError] = useState<string>('');

    const createBackend = async (url: string, token: string, name: string) => {
        const encryptedData = await encryptWithPublicKey({
            backendUrl: url,
            backendToken: token,
            name: name
        })

        instance.post<boolean>('/oq/backend/list/encrypted', {payload: encryptedData}).then((value) => {
            // setUserPassword(password);
            // setUserCreated(true);
            // setUserCreateError('');
            props.reloadParent();
        }).catch((reason) => {
            switch (reason.response.status) {
                case 422:
                    setUserCreateError('Username already exist. Select another.')
                    break;
            }
        })
    }

    return (
        <PopupDialog title={'Create new online qr backend'} maxWidth={'sm'} onClose={()=>{props.onClose()}}>
            <React.Fragment>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    label="backendName"
                    autoFocus
                    value={backendName}
                    onChange={(e)=>{setBackendName(e.target.value)}}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    label="backendUrl"
                    autoFocus
                    value={backendUrl}
                    onChange={(e)=>{setBackendUrl(e.target.value)}}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    label="backendToken"
                    autoFocus
                    value={backendToken}
                    onChange={(e)=>{setBackendToken(e.target.value)}}
                />
                {
                    !isUserCreated &&
                    <Button sx={{mt: 2}} variant="outlined" fullWidth onClick={createBackend.bind(null, backendUrl, backendToken, backendName)} disabled={backendUrl.length === 0 || backendToken.length === 0 || backendName.length === 0}>Create new backend</Button>
                }
                {
                    userCreateError &&
                    <Alert severity="error" sx={{mt: 2}}>{userCreateError}</Alert>
                }

            </React.Fragment>
        </PopupDialog>
    )
}
