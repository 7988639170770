import {
    Avatar,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Paper
} from "@mui/material";
import {useEffect, useState} from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import React from "react";
import AppMenu from "../AppBar/AppMenu";
import CssBaseline from "@mui/material/CssBaseline";
import Button from "@mui/material/Button";
import PersonIcon from '@mui/icons-material/Person';
import PopupDialog from "../Components/Dialog/PopupDialog";
import instance from "../axios";
import OQBackendCreateDialog from "./OQBackendCreateDialog";
import FavoriteIcon from '@mui/icons-material/Favorite';

export interface OnlineQrBackendItem {
    id: number;
    name: string;
    isHealth: boolean
}

export default function OQBackendList() {
    const [userList, setUserList] = useState<OnlineQrBackendItem[]>([])

    const [isNeedCreateOQB, setNeedCreateOQB] = useState<boolean>(false);

    const loadBackendList = () => {
        instance.get('/oq/backend/list').then(value => {
            setUserList(value.data)
        }).catch(reason => {

        })
    }

    useEffect(()=>{
        loadBackendList()
    }, [])

    return (
        <React.Fragment>
            <AppMenu header={'Online qr backend'}/>
            <CssBaseline />
            <div style={{display: "flex"}}>
                <Button sx={{mx: 2, mt: 2}} variant="outlined" fullWidth onClick={()=>{setNeedCreateOQB(true)}}>Create new</Button>
            </div>
            <Paper sx={{m: 2}}>
                <List>
                    {
                        userList.map((value, index) => (
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar>
                                        <FavoriteIcon color={value.isHealth ? "success" : "error"}/>
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={value.name}
                                />
                            </ListItem>
                        ))
                    }
                </List>
            </Paper>
            {
                isNeedCreateOQB &&
                <OQBackendCreateDialog reloadParent={()=>{loadBackendList()}} onClose={()=>{setNeedCreateOQB(false)}}/>
            }
        </React.Fragment>

    )
}
