import React, {useEffect, useState} from "react";
import PopupDialog from "../../Components/Dialog/PopupDialog";
import instance from "../../axios";
import {Avatar, Card, CardActions, CardHeader, IconButton} from "@mui/material";
import {blue, green, orange, red} from "@mui/material/colors";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import ShareIcon from "@mui/icons-material/Share";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import PaymentAcquiring from "./PaymentAcquiring";
import EmailResend from "./EmailResend";

const getProviderNameByTypeId = (type: number) => {
  switch (type) {
      case 0: return 'Oschadbank';
      case 1: return 'Monobank';
      case 10: return 'Fenige';
      default: return '';
  }
}

const getAvatar = (status: number) => {
    switch (status) {
        case 0:
            return (
                <Avatar sx={{ bgcolor: green[500] }} variant="square">
                    S
                </Avatar>
            );
        case 1:
        case 200:
            return (
                <Avatar sx={{ bgcolor: blue[500] }} variant="square">
                    W
                </Avatar>
            );
        case 2:
            return (
                <Avatar sx={{ bgcolor: orange[500] }} variant="square">
                    P
                </Avatar>
            );
        case 100:
            return (
                <Avatar sx={{ bgcolor: red[500] }} variant="square">
                    L
                </Avatar>
            );
    }
}

const getStatusDescription = (status: number) => {
    switch (status) {
        case 0:
            return 'Paid online'
        case 1:
        case 200:
            return 'Ready'
        case 2:
            return 'Wait confirmation from acquire'
        case 100:
            return 'Refunded'
    }
}

interface Props {
    uuid: string
    backend: number
    onClose: () => void
}

interface CartAttemptProvider {
    paymentStatus: number
    paymentProvider: {
        id: number;
        type: number;
        processingUrl: string;
        currency: string;
        currencyCode: number;
        currencyLocal: string;
    }
    isMultistage: boolean
}

export default function PaymentInfo(props: Props) {
    const [attempt, setAttempt] = useState<CartAttemptProvider[]>([]);
    const [selectedPaymentProvider, setSelectedPaymentProvider] = useState<number>(0);

    const [isNeedEmailResend, setNeedEmailResend] = useState<boolean>(false)
    const [isNeedShowPaymentHistory, setNeedShowPaymentHistory] = useState<boolean>(false)

    const loadAttemptsHistory = (uuid: string, backend: number) => {
        instance.post(`/oq/s2s/${backend}/qr/cart/attempts`, {uuid}).then(value => {
            console.log(value.data)
            setAttempt(value.data)
        }).catch(reason => {

        })
    }

    useEffect(()=>{
        if (props.uuid && props.backend){
            loadAttemptsHistory(props.uuid, props.backend)
        }
    }, [props.uuid, props.backend])

    return (
        <PopupDialog title={props.uuid} onClose={()=>{props.onClose()}} maxWidth={'sm'} actions={
            <React.Fragment>
                <IconButton href={`https://qr.pasport.org.ua/v2/${props.uuid}`} target='_blank'>
                    <ShareIcon />
                </IconButton>
                <IconButton onClick={()=>{loadAttemptsHistory(props.uuid, props.backend)}}>
                    <AutorenewIcon/>
                </IconButton>
            </React.Fragment>

        }>
            <React.Fragment>
                {
                    attempt.map((value, index) => (
                        <Card sx={{mt: 2, width: '100%'}} key={`it-${index}`}>
                            <CardHeader
                                avatar={
                                    getAvatar(value.paymentStatus)
                                }
                                title={`${getProviderNameByTypeId(value.paymentProvider.type)}`}
                                subheader={`${getStatusDescription(value.paymentStatus)}`}
                            />
                            <CardActions disableSpacing>
                                <IconButton href={`https://api.pa.airas.com.ua/oq/s2s/${props.backend}/qr/cart/client-pdf/${props.uuid}/${value.paymentProvider.id}`} target='_blank'>
                                    <PictureAsPdfIcon />
                                </IconButton>

                                <IconButton onClick={()=>{setSelectedPaymentProvider(value.paymentProvider.id); setNeedEmailResend(true);}} disabled={value.paymentStatus !== 0}>
                                    <AlternateEmailIcon />
                                </IconButton>

                                <IconButton onClick={()=>{setSelectedPaymentProvider(value.paymentProvider.id); setNeedShowPaymentHistory(true);}} disabled={!(value.paymentStatus === 0 || value.paymentStatus === 100 || value.paymentStatus === 200)}>
                                    <CreditCardIcon />
                                </IconButton>
                            </CardActions>
                        </Card>
                    ))
                }
                {
                    selectedPaymentProvider > 0 && isNeedShowPaymentHistory &&
                    <PaymentAcquiring provider={selectedPaymentProvider} backend={props.backend} uuid={props.uuid} onClose={()=>{setSelectedPaymentProvider(0); setNeedShowPaymentHistory(false);}}/>
                }
                {
                    selectedPaymentProvider > 0 && isNeedEmailResend &&
                    <EmailResend provider={selectedPaymentProvider} backend={props.backend} uuid={props.uuid} onClose={()=>{setSelectedPaymentProvider(0); setNeedEmailResend(false);}}/>
                }
            </React.Fragment>
        </PopupDialog>
    )
}
