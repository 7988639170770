import {Alert, Checkbox, Dialog, DialogContent, DialogTitle, IconButton} from "@mui/material";
import React, {useEffect, useState} from "react";
import encryptWithPublicKey from "../crypto";
import generatePassword from "../generator";
import PopupDialog from "../Components/Dialog/PopupDialog";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import instance from "../axios";

export interface Props {
    userId: number
    reloadParent: () => void
    onClose: () => void
}

export default function UserResetPasswordDialog(props: Props) {
    const [userPassword, setUserPassword] = useState<string>('');
    const [isUserRestored, setUserRestored] = useState<boolean>(false);
    const [userRestoreError, setUserRestoreError] = useState<string>('');

    const restoreUser = async (userId: number, password: string) => {
        const encryptedData = await encryptWithPublicKey({
            password,
        })

        instance.post<boolean>(`/user/list/${userId}/password-update/encrypted`, {payload: encryptedData}, {withCredentials: true}).then((value) => {
            setUserPassword(password);
            setUserRestored(true);
            setUserRestoreError('');
            props.reloadParent();
        }).catch((reason) => {
            setUserRestoreError(reason.response.status)
            // switch (reason.response.status) {
            //     case 422:
            //         setUserCreateError('Username already exist. Select another.')
            //         break;
            // }
        })
    }

    const beginRestoringUser = (id: number) => {
        restoreUser(id, generatePassword(16))
    }

    useEffect(()=>{
        setUserPassword('')
        setUserRestored(false)
        setUserRestoreError('')
    }, []);

    return (
        <PopupDialog title={'Reset user password'} maxWidth={'sm'} onClose={()=>{props.onClose()}}>
            <React.Fragment>
                {
                    !isUserRestored &&
                    <Button variant="outlined" fullWidth onClick={beginRestoringUser.bind(null, props.userId)} disabled={userRestoreError.length > 0}>Reset user password</Button>
                }

                {
                    userRestoreError &&
                    <Alert severity="error" sx={{mt: 2}}>{userRestoreError}</Alert>
                }
                {
                    isUserRestored &&
                    <React.Fragment>
                        <TextField
                            margin="normal"
                            label="Password"
                            fullWidth
                            value={userPassword}
                            disabled
                            color={"error"}
                        />
                        <Alert severity="warning" sx={{mt: 2}}>User password changed. Copy new user password.</Alert>
                    </React.Fragment>

                }
            </React.Fragment>
        </PopupDialog>
    )
}
